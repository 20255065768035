import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Entry from './main/App';
import reportWebVitals from './reportWebVitals';

import 'bootstrap/dist/css/bootstrap.min.css';


ReactDOM.render(<Entry /> ,document.getElementById('root'));


reportWebVitals();
